import { axiosCsisApi } from "@csis.com/tip/src/App";
import { STRINGIFY_QUERY_PARAMS } from "@csis.com/tip/src/api/utils";
import { QueryParams } from "../types";
import { mapAuditLogStartAndEndDateToCorrectType } from "./utils";

export async function fetchAuditLogApi(queryParams: Partial<QueryParams>) {
  const query = mapAuditLogStartAndEndDateToCorrectType(queryParams);

  return axiosCsisApi.getEventsApi10AuditlogGet(
    { ...query },
    STRINGIFY_QUERY_PARAMS,
  );
}

export async function exportAuditLogCsvApi(
  _queryParams: Partial<QueryParams>,
) {}
