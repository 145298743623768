/* eslint-disable sonarjs/no-duplicate-string */
import { lazy } from "react";
import { EmergencyCasePage } from "@csis.com/tip/src/pages/EmergencyCases/EmergencyCase/EmergencyCase";
import { ApiDocumentation } from "@csis.com/tip/src/pages/HelpAndSupport/ApiDocumentation/ApiDocumentation";
import { Mdr } from "@csis.com/tip/src/pages/HelpAndSupport/Mdr/Mdr";
import NoMatchUrl from "@csis.com/tip/src/pages/NoMatchUrl/NoMatchUrl";
import LandingPage from "@csis.com/tip/src/pages/Products/LandingPage/LandingPage";
import RetainerPage from "../pages/Retainers/Retainer/Retainer";
import { Route } from "./types";
import urls from "./urls";

// Global Search
const GlobalSearch = lazy(
  () => import("@csis.com/tip/src/pages/GlobalSearch/GlobalSearch"),
);
// Emergency case confirmation
const EmergencyCaseConfirmation = lazy(
  () =>
    import(
      "@csis.com/tip/src/pages/EmergencyCaseConfirmation/EmergencyCaseConfirmation"
    ),
);

// Emergency response
const EmergencyResponseRetainerInformation = lazy(
  () =>
    import(
      "@csis.com/tip/src/pages/Products/EmergencyResponseRetainer/EmergencyResponseRetainer"
    ),
);
const EmergencyResponseRetainerSearch = lazy(
  () =>
    import("@csis.com/tip/src/pages/Retainers/RetainersSearch/RetainersSearch"),
);

const EmergencyCaseSearch = lazy(
  () =>
    import(
      "@csis.com/tip/src/pages/EmergencyCases/EmergencyCaseSearch/EmergencyCaseSearch"
    ),
);

// Phishing
const PhishingThreats = lazy(
  () =>
    import("@csis.com/tip/src/pages/Products/Phishing/UrlsSearch/UrlsSearch"),
);
const PhishingThreat = lazy(
  () => import("@csis.com/tip/src/pages/Products/Phishing/Url/Url"),
);
const PhishingStatistics = lazy(
  () =>
    import("@csis.com/tip/src/pages/Products/Phishing/Statistics/Statistics"),
);
const PhishingUsage = lazy(
  () => import("@csis.com/tip/src/pages/Products/Phishing/Usage/Usage"),
);

// Compromised Data
const CompromisedDataCCSearch = lazy(
  () =>
    import(
      "@csis.com/tip/src/pages/Products/CompromisedData/CreditCards/Search/CreditCardsSearch"
    ),
);

const CompromisedDataCCReport = lazy(
  () =>
    import(
      "@csis.com/tip/src/pages/Products/CompromisedData/CreditCards/Report/Report"
    ),
);
const CompromisedDataCCStatistics = lazy(
  () =>
    import(
      "@csis.com/tip/src/pages/Products/CompromisedData/CreditCards/Statistics/Statistics"
    ),
);

const CompromisedDataVictimsSearch = lazy(
  () =>
    import(
      "@csis.com/tip/src/pages/Products/CompromisedData/VictimsInfo/Search/VictimsInfoReports"
    ),
);

const CompromisedDataVictimsReport = lazy(
  () =>
    import(
      "@csis.com/tip/src/pages/Products/CompromisedData/VictimsInfo/Report/Report"
    ),
);
const CompromisedDataVictimsStatistics = lazy(
  () =>
    import(
      "@csis.com/tip/src/pages/Products/CompromisedData/VictimsInfo/Statistics/Statistics"
    ),
);

// Investigations
const InvestigationsTopLevelPage = lazy(
  () => import("@csis.com/tip/src/pages/Products/Investigations/TopLevelPage"),
);

//Alerts
const AlertStatistics = lazy(
  () => import("@csis.com/tip/src/pages/Alerts/Statistics/Statistics"),
);
const SearchAlerts = lazy(
  () => import("@csis.com/tip/src/pages/Alerts/AlertsSearch/AlertsSearch"),
);
const Alert = lazy(() => import("@csis.com/tip/src/pages/Alerts/Alert/Alert"));

//Tickets
const SearchTickets = lazy(
  () => import("@csis.com/tip/src/pages/Tickets/TicketsSearch/TicketsSearch"),
);
const TicketsStatistics = lazy(
  () => import("@csis.com/tip/src/pages/Tickets/Statistics/Statistics"),
);
const Ticket = lazy(
  () => import("@csis.com/tip/src/pages/Tickets/Ticket/Ticket"),
);

// Threat Insights
const ThreatInsights = lazy(
  () => import("@csis.com/tip/src/pages/ThreatInsights/Search/ThreatInsights"),
);
const Article = lazy(
  () => import("@csis.com/tip/src/pages/ThreatInsights/Article/Article"),
);

// Organizations - Internal

const AuditLogInternal = lazy(
  () =>
    import(
      "@csis.com/tip/src/pages/Organization/Internal/Administration/AuditLog/AuditLog"
    ),
);

const CustomersSearch = lazy(
  () =>
    import(
      "@csis.com/tip/src/pages/Organization/Internal/Customers/Search/Search"
    ),
);

const CustomersDashboard = lazy(
  () =>
    import(
      "@csis.com/tip/src/pages/Organization/Internal/Customers/Dashboard/Dashboard"
    ),
);

const UsersSearch = lazy(
  () =>
    import(
      "@csis.com/tip/src/pages/Organization/Internal/Customers/Users/UsersSearch"
    ),
);

const UsersPageInternal = lazy(
  () =>
    import(
      "@csis.com/tip/src/pages/Organization/Internal/Administration/Users/UsersPage"
    ),
);

const AccessPageInternal = lazy(
  () =>
    import(
      "@csis.com/tip/src/pages/Organization/Internal/Administration/Access/AccessPage"
    ),
);

const CustomerInfoInternal = lazy(
  () =>
    import(
      "@csis.com/tip/src/pages/Organization/Internal/Administration/CustomerInfo/CustomerInfo"
    ),
);

const CustomerServices = lazy(
  () =>
    import(
      "@csis.com/tip/src/pages/Organization/Internal/Administration/Services/Services"
    ),
);

const CustomerHealth = lazy(
  () =>
    import(
      "@csis.com/tip/src/pages/Organization/Internal/Administration/Health/Health"
    ),
);

// Organization - Administration
const CustomerInfo = lazy(
  () =>
    import(
      "@csis.com/tip/src/pages/Organization/Administration/CustomerInfo/CustomerInfo"
    ),
);

const AuditLog = lazy(
  () =>
    import(
      "@csis.com/tip/src/pages/Organization/Administration/AuditLog/AuditLog"
    ),
);

const UsersPage = lazy(
  () =>
    import(
      "@csis.com/tip/src/pages/Organization/Administration/Users/UsersPage"
    ),
);

const AccessPage = lazy(
  () =>
    import(
      "@csis.com/tip/src/pages/Organization/Administration/Access/AccessPage"
    ),
);

// Settings
const Security = lazy(
  () => import("@csis.com/tip/src/pages/Profile/Security/Security"),
);

const NotificationSettings = lazy(
  () =>
    import(
      "@csis.com/tip/src/pages/Profile/NotificationSettings/NotificationSettings"
    ),
);

// Reports
const Reports = lazy(() => import("@csis.com/tip/src/pages/Reports/Reports"));

// MDR
const OnboardingMdfeForm = lazy(
  () => import("@csis.com/tip/src/mdr/onboarding/mdfe/OnboardingForm"),
);

// TT
const OnboardingTigerTeamForm = lazy(
  () => import("@csis.com/tip/src/mdr/onboarding/tigerteam/OnboardingForm"),
);

const routes: Route[] = [
  {
    path: urls.products,
    component: LandingPage,
    title: "threat_intelligence_portal",
    subtitle: "dashboard",
  },
  {
    path: urls.globalsearch,
    component: GlobalSearch,
    title: "threat_intelligence_portal",
    subtitle: "search",
  },
  // Emergency Response
  {
    path: urls.products_emergencyresponse_retainer,
    component: EmergencyResponseRetainerInformation,
    title: "emergency_response",
    subtitle: "retainer_information",
  },
  // Phishing
  {
    path: urls.products_phishing,
    component: PhishingThreats,
    title: "anti-phishing",
    subtitle: "urls",
  },
  {
    path: urls.products_phishing_urls,
    component: PhishingThreats,
    title: "anti-phishing",
    subtitle: "urls",
  },
  {
    path: urls.products_phishing_urls_url_id,
    component: PhishingThreat,
    title: "anti-phishing",
    subtitle: "url",
  },
  {
    path: urls.products_phishing_statistics,
    component: PhishingStatistics,
    title: "anti-phishing",
    subtitle: "statistics",
  },
  {
    path: urls.products_phishing_usage,
    component: PhishingUsage,
    title: "anti-phishing",
    subtitle: "service_usage",
  },
  //Compromised Data
  {
    path: urls.products_compromiseddata_cc,
    component: CompromisedDataCCSearch,
    title: "payment_cards",
    subtitle: "search",
  },
  {
    path: urls.products_compromiseddata_cc_search,
    component: CompromisedDataCCSearch,
    title: "payment_cards",
    subtitle: "search",
  },
  {
    path: urls.products_compromiseddata_cc_report_id,
    component: CompromisedDataCCReport,
    title: "payment_cards",
    subtitle: "report",
  },
  {
    path: urls.products_compromiseddata_cc_statistics,
    component: CompromisedDataCCStatistics,
    title: "payment_cards",
    subtitle: "statistics",
  },

  {
    path: urls.products_compromiseddata_victims,
    component: CompromisedDataVictimsSearch,
    title: "credentials",
    subtitle: "search",
  },
  {
    path: urls.products_compromiseddata_victims_search,
    component: CompromisedDataVictimsSearch,
    title: "credentials",
    subtitle: "search",
  },
  {
    path: urls.products_compromiseddata_victims_report_id,
    component: CompromisedDataVictimsReport,
    title: "credentials",
    subtitle: "report",
  },
  {
    path: urls.products_compromiseddata_victims_statistics,
    component: CompromisedDataVictimsStatistics,
    title: "credentials",
    subtitle: "statistics",
  },

  //Investigations
  {
    path: urls.products_investigations,
    component: InvestigationsTopLevelPage,
    title: "investigations",
    subtitle: "search",
  },
  {
    path: urls.products_investigations_search,
    component: InvestigationsTopLevelPage,
    title: "investigations",
    subtitle: "search",
  },
  {
    path: urls.products_investigations_threatcloud,
    component: InvestigationsTopLevelPage,
    title: "investigations",
    subtitle: "threat_cloud",
  },
  // Alerts
  {
    path: urls.alerts,
    component: SearchAlerts,
    title: "alerts",
    subtitle: "search",
  },
  {
    path: urls.alerts_statistics,
    component: AlertStatistics,
    title: "alerts",
    subtitle: "statistics",
  },
  {
    path: urls.alerts_search,
    component: SearchAlerts,
    title: "alerts",
    subtitle: "search",
  },
  {
    path: urls.alerts_alert_id,
    component: Alert,
    title: "alerts",
    subtitle: "search",
  },
  // Tickets
  {
    path: urls.tickets,
    component: SearchTickets,
    title: "tickets",
    subtitle: "search",
  },
  {
    path: urls.tickets_search,
    component: SearchTickets,
    title: "tickets",
    subtitle: "search",
  },
  {
    path: urls.tickets_statistics,
    component: TicketsStatistics,
    title: "tickets",
    subtitle: "statistics",
  },
  {
    path: urls.tickets_ticket_id,
    component: Ticket,
    title: "tickets",
    subtitle: "search",
  },
  {
    path: urls.products_threatinsights,
    component: ThreatInsights,
    title: "threat_insights",
    subtitle: "search",
  },
  {
    path: urls.products_threatinsights_article_id,
    component: Article,
    title: "threat_insights",
    subtitle: "article",
  },

  //Settings
  {
    path: urls.settings_account_general,
    component: Security,
    title: "account",
    subtitle: "general",
  },
  {
    path: urls.settings_account,
    component: Security,
    title: "account",
    subtitle: "general",
  },
  {
    path: urls.settings_account_notifications,
    component: NotificationSettings,
    title: "account",
    subtitle: "notifications",
  },
  // Organizations - Internal
  {
    path: urls.organizations_customers_search,
    component: CustomersSearch,
    title: "organization",
    subtitle: "customers",
  },
  {
    path: urls.organizations_customers_dashboard,
    component: CustomersDashboard,
    title: "organization",
    subtitle: "customers",
  },
  {
    path: urls.organizations_customers_users,
    component: UsersSearch,
    title: "organization",
    subtitle: "users",
  },
  {
    path: urls.organizations_administration_id,
    component: CustomerInfoInternal,
    title: "organization",
    subtitle: "customer",
  },
  {
    path: urls.organizations_administration_id_auditlog,
    component: AuditLogInternal,
    title: "organization",
    subtitle: "audit_log",
  },
  {
    path: urls.organizations_administration_id_users,
    component: UsersPageInternal,
    title: "organization",
    subtitle: "users",
  },
  {
    path: urls.organizations_administration_id_access,
    component: AccessPageInternal,
    title: "organization",
    subtitle: "access",
  },
  {
    path: urls.organizations_administration_id_services,
    component: CustomerServices,
    title: "organization",
    subtitle: "customer",
  },
  {
    path: urls.organizations_administration_id_health,
    component: CustomerHealth,
    title: "organization",
    subtitle: "health",
  },

  // Administration
  {
    path: urls.administration,
    component: CustomerInfo,
    title: "organization",
    subtitle: "customer",
  },
  {
    path: urls.administration_auditlog,
    component: AuditLog,
    title: "organization",
    subtitle: "audit_log",
  },
  {
    path: urls.administration_users,
    component: UsersPage,
    title: "organization",
    subtitle: "users",
  },
  {
    path: urls.administration_access,
    component: AccessPage,
    title: "organization",
    subtitle: "access",
  },

  {
    path: urls.administration,
    component: CustomerInfo,
    title: "organization",
    subtitle: "customer",
  },

  // Retainers
  {
    path: urls.ir_retainers_search,
    component: EmergencyResponseRetainerSearch,
    title: "retainers",
    subtitle: "search",
  },
  {
    path: urls.ir_retainers_retainer_id,
    component: RetainerPage,
    title: "retainers",
    subtitle: "search",
  },
  // Emergency Cases
  {
    path: urls.ir_cases,
    component: EmergencyCaseSearch,
    title: "emergency_cases",
    subtitle: "emergency_cases",
  },
  {
    path: urls.ir_cases_search,
    component: EmergencyCaseSearch,
    title: "emergency_cases",
    subtitle: "search",
  },
  {
    path: urls.ir_cases_case_id,
    component: EmergencyCasePage,
    title: "emergency_case",
    subtitle: "emergency_case",
  },
  // Help & Support
  {
    path: urls.helpandsupport,
    component: ApiDocumentation,
    title: "help_and_support",
    subtitle: "help_center",
  },
  {
    path: urls.helpandsupport_apidocumentation,
    component: ApiDocumentation,
    title: "help_and_support",
    subtitle: "api_documentation",
  },
  // {
  //   path: urls.helpandsupport_portalmanual,
  //   component: ,
  //   title: "help_and_support",
  //   subtitle: "portal_manual",
  // },
  {
    path: urls.helpandsupport_mdr,
    component: Mdr,
    title: "help_and_support",
    subtitle: "mdr",
  },
  // Reports
  {
    path: urls.reports,
    component: Reports,
    title: "reports",
    subtitle: "reports",
  },
  // MDR
  {
    path: urls.onboarding_mdfecustomer,
    component: OnboardingMdfeForm,
    title: "mdfe",
    subtitle: "onboarding",
  },
  {
    path: urls.onboarding_mdfecustomer_id,
    component: OnboardingMdfeForm,
    title: "mdfe",
    subtitle: "onboarding",
  },
  // Emergency case confirmation
  {
    path: urls.emergency_case_confirmation_id,
    component: EmergencyCaseConfirmation,
    title: "emergency_cases",
    subtitle: "emergency_case_confirmation",
  },
  // TT
  {
    path: urls.onboarding_tigerteam,
    component: OnboardingTigerTeamForm,
    title: "incident_response",
    subtitle: "assessment_questions",
  },
  //404 - this has to be at the end! since it catches all that dont match the above
  {
    path: "*",
    component: NoMatchUrl,
    title: "threat_intelligence_portal",
    subtitle: "not_found",
  },
];

export default routes;
